.ZkIAMG_modal {
  border: 1px solid var(--neutral-dark);
  background-color: #fff;
  border-radius: .5em;
  flex-direction: column;
  align-items: center;
  row-gap: 1em;
  min-width: 50em;
  max-width: 80%;
  max-height: 70vh;
  margin: auto;
  display: flex;
  position: relative;
  overflow-y: scroll;
}

.ZkIAMG_modal section {
  flex-direction: column;
  align-items: start;
  row-gap: 2em;
  width: 100%;
  padding: 2em 2em 1em;
  display: flex;
  overflow-y: scroll;
}

.ZkIAMG_modal ul {
  margin-inline-start: .5em;
  list-style: inside;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjpudWxsLCJtYXBwaW5ncyI6IkFBQUE7Ozs7Ozs7Ozs7Ozs7Ozs7QUFnQkE7Ozs7Ozs7Ozs7QUFVQSIsInNvdXJjZXMiOlsiLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vc3JjL3BhZ2VzL0FkbWluL3N1YnNjcmlwdGlvbi9DYW5jZWxNb2RhbC9Nb2RhbC5tb2R1bGUuY3NzIl0sInNvdXJjZXNDb250ZW50IjpbIi5tb2RhbCB7XG4gICAgZGlzcGxheTogZmxleDtcbiAgICBhbGlnbi1pdGVtczogY2VudGVyO1xuICAgIGZsZXgtZGlyZWN0aW9uOiBjb2x1bW47XG4gICAgYmFja2dyb3VuZC1jb2xvcjogd2hpdGU7XG4gICAgYm9yZGVyOiAxcHggc29saWQgdmFyKC0tbmV1dHJhbC1kYXJrKTtcbiAgICBib3JkZXItcmFkaXVzOiAwLjVlbTtcbiAgICBtYXJnaW46IGF1dG87XG4gICAgbWF4LWhlaWdodDogNzB2aDtcbiAgICBwb3NpdGlvbjogcmVsYXRpdmU7XG4gICAgb3ZlcmZsb3cteTogc2Nyb2xsO1xuICAgIHJvdy1nYXA6IDFlbTtcbiAgICBtYXgtd2lkdGg6IDgwJTtcbiAgICBtaW4td2lkdGg6IDUwZW07XG59XG5cbi5tb2RhbCBzZWN0aW9uIHtcbiAgICBwYWRkaW5nOiAyZW0gMmVtIDFlbSAyZW07XG4gICAgZGlzcGxheTogZmxleDtcbiAgICBmbGV4LWRpcmVjdGlvbjogY29sdW1uO1xuICAgIHJvdy1nYXA6IDJlbTtcbiAgICBvdmVyZmxvdy15OiBzY3JvbGw7XG4gICAgYWxpZ24taXRlbXM6IHN0YXJ0O1xuICAgIHdpZHRoOiAxMDAlO1xufVxuXG4ubW9kYWwgdWwge1xuICAgIG1hcmdpbi1pbmxpbmUtc3RhcnQ6IDAuNWVtO1xuICAgIGxpc3Qtc3R5bGU6IGRpc2MgaW5zaWRlO1xufVxuIl0sIm5hbWVzIjpbXX0= */