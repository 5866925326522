import type * as PlanConfigJson from '@punnet/product-pure'
import rawPlanConfig from './src/sovereign-plan-config.json'

export const SovereignPlanConfig = rawPlanConfig as PlanConfigJson.Config



        import * as me from '@sovereign/product-config'
        
        Object.entries(me).forEach(([exportedAs, exported]) => {
            if (exported && Object.isExtensible(exported) && typeof exported === 'function') {
                exported[Symbol.for('__WORKSPACE_HOME')] = '/Users/clairechia/workspace/peachy-repo/sovereign/product/config'
                exported[Symbol.for('__WORKSPACE_NAME')] = '@sovereign/product-config'
                exported[Symbol.for('__EXPORTED_AS')] = exportedAs
            }
        })
    