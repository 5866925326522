export * from './src/claims-activity-api'
        import * as me from '@peachy/claims-activity-pure'
        
        Object.entries(me).forEach(([exportedAs, exported]) => {
            if (exported && Object.isExtensible(exported) && typeof exported === 'function') {
                exported[Symbol.for('__WORKSPACE_HOME')] = '/Users/clairechia/workspace/peachy-repo/comp-kit/claims-activity/pure'
                exported[Symbol.for('__WORKSPACE_NAME')] = '@peachy/claims-activity-pure'
                exported[Symbol.for('__EXPORTED_AS')] = exportedAs
            }
        })
    