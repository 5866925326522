.jPNcQW_PasswordBox {
  display: flex;
  position: relative;
}

.jPNcQW_PasswordBox i {
  color: var(--peachy-pink);
  display: flex;
  position: absolute;
  top: 50%;
  right: .5em;
  transform: translateY(-50%);
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjpudWxsLCJtYXBwaW5ncyI6IkFBQUE7Ozs7O0FBS0EiLCJzb3VyY2VzIjpbIi4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uL3NyYy9jb21wb25lbnRzL1RleHRCb3gvUGFzc3dvcmRCb3gubW9kdWxlLmNzcyJdLCJzb3VyY2VzQ29udGVudCI6WyIuUGFzc3dvcmRCb3gge1xuICAgIHBvc2l0aW9uOiByZWxhdGl2ZTtcbiAgICBkaXNwbGF5OiBmbGV4O1xufVxuXG4uUGFzc3dvcmRCb3ggaSB7XG4gICAgcG9zaXRpb246IGFic29sdXRlO1xuICAgIHRyYW5zZm9ybTogdHJhbnNsYXRlWSgtNTAlKTtcbiAgICBkaXNwbGF5OiBmbGV4O1xuICAgIHJpZ2h0OiAwLjVlbTtcbiAgICBjb2xvcjogdmFyKC0tcGVhY2h5LXBpbmspO1xuICAgIHRvcDogNTAlO1xufVxuIl0sIm5hbWVzIjpbXX0= */