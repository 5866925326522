import {PeachyFlashRepo} from '@peachy/flash-repo-peachy-client'
import {ApplicationContextFactory} from '../ApplicationContextFactory'
import { peachyProductConfigService } from '@peachy/repo-domain'

/**
 * This is a first stab at making the application context available in a lambda and should probably only be used for read only operations for now.
 * It's not completely wired up (see all the undefineds below) because it as initially designed for use in the mobile app only and is
 * kinda shoehorned into being made available for use in a lambda here.
 * Probably needs a better seperation of concerns rethink, but for now, it's useful if you know it's limitations
 */
//todo-dp this is peachy specific, will probably need a Sov one
export class LambdaCustomerApplicationContext {

    public static async for(repo: PeachyFlashRepo) {
        return ApplicationContextFactory.buildOnDemandFactory({
            peachyClient: () => undefined,
            config: () => undefined,
            enquiryDefinitions: () => [],
            peachyFlashRepo: () => repo,
            remoteRepoAdapter: () => undefined,
            imageCompressor: () => undefined,
            healthHeroClient: () => undefined,
            productConfigService: () => peachyProductConfigService
        })
    }
}
