export * from './src/types'
export * from './src/repositories/abstract/PeachyArrayRepository'
export * from './src/repositories/abstract/PeachyMapRepository'

export * from './src/repositories/AppointmentRepository'
export * from './src/repositories/ClaimActivityRepository'
export * from './src/repositories/EnquiryRepository'
export * from './src/repositories/InProgressRepository'
export * from './src/repositories/ManagedLifeRepository'
export * from './src/repositories/NonManagedLifeRepository'
export * from './src/repositories/PlanRepository'
export * from './src/repositories/PolicyRepository'
export * from './src/repositories/PreferencesRepository'

export * from './src/migrations/PeachyFlashRepoMigrationRunner'

        import * as me from '@peachy/flash-repo-peachy-client'
        
        Object.entries(me).forEach(([exportedAs, exported]) => {
            if (exported && Object.isExtensible(exported) && typeof exported === 'function') {
                exported[Symbol.for('__WORKSPACE_HOME')] = '/Users/clairechia/workspace/peachy-repo/comp-kit/flash-repo/peachy-client'
                exported[Symbol.for('__WORKSPACE_NAME')] = '@peachy/flash-repo-peachy-client'
                exported[Symbol.for('__EXPORTED_AS')] = exportedAs
            }
        })
    